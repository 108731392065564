import { AxiosPromise } from 'axios';
import MonthReportInfoClass from '@/model/monthReport/MonthReportInfoClass';
import axios from '../AxiosBase';

export default {

  /**
   * ログインユーザの月末報告一覧を取得
   * @param userid
   * @returns
   */
  findAll():AxiosPromise<any> {
    return axios.get('monthReport/findAll');
  },

  /**
   * 月末報告一覧データを取得する(ユーザ指定)
   * @param userid
   * @returns
   */
  findAllByPrivUser(userId:any):AxiosPromise<any> {
    return axios.get(`admin/monthReport/findAll/${userId}`);
  },

  /**
   * ログインユーザの月末報告データを登録・更新する
   * @param MonthReportInfoClass
   * @returns
   */
  save(monthReportInfo:MonthReportInfoClass):AxiosPromise<any> {
    return axios.post('monthReport/save', monthReportInfo);
  },

  /**
   * 月末報告データを登録・更新する(ユーザ指定)
   * @param MonthReportInfoClass
   * @returns
   */
  saveByPrivUser(monthReportInfo:MonthReportInfoClass, userId: string):AxiosPromise<any> {
    return axios.post(`admin/monthReport/save/${userId}`, monthReportInfo);
  },

  /**
   * 添付ファイルのアップロード
   * @param MonthReportInfoClass
   * @returns
   */
  upload(data:FormData):AxiosPromise<any> {
    return axios.post('monthReport/upload', data);
  },

  /**
   * 月末報告一覧データを削除する
   * @param MonthReportInfoClass
   * @returns
   */
  delete(monthReportInfo:MonthReportInfoClass):AxiosPromise<any> {
    return axios.post('monthReport/delete', monthReportInfo);
  }
};

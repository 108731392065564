
import { Vue, Component, Prop } from 'vue-property-decorator';
import MonthReportInfoClass from '@/model/monthReport/MonthReportInfoClass';
import MonthlyReportRegister from '@/views/MonthReport/MonthlyReportRegister.vue';
import MonthReportID from '@/model/monthReport/MonthReportID';
import MonthReportApi from '@/module/api/MonthReportApi';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import MonthStatus from '@/model/monthReport/MonthStatus';
import TypeJpDate from '@/model/type/TypeJpDate';
import Help from '@/components/Parts/Help.vue';
import MessageShowBox from '@/components/post/MessageShowBox.vue';
import InformationApi from '@/module/api/InformationApi';

@Component({
  components: {
    MonthlyReportRegister,
    Help,
    MessageShowBox
  }
})
export default class MonthlyReport extends Vue {
  @Prop() reportData!: MonthReportInfoClass;
  @Prop() loginUser!: UserBaseInfo;
  @Prop() userId!: string;
  @Prop({ default: false }) defaultOpen!: boolean;
  displayData: MonthReportInfoClass = new MonthReportInfoClass();
  showDialog = false;
  showDetail=false;

  mounted(): void {
    if (this.defaultOpen) {
      this.showDetail = true;
      if (!this.reportData.postId.isEmpty()) {
        InformationApi.readed(this.reportData.postId);
      }
    }
  }

  switchDetail(): void {
    this.showDetail = !this.showDetail;
    if (this.showDetail) {
      if (!this.reportData.postId.isEmpty()) {
        InformationApi.readed(this.reportData.postId);
      }
    }
  }

  /**
   * 編集ボタン押下
   */
  edit(): void {
    this.displayData = MonthReportInfoClass.clone(this.reportData);
    this.showDialog = true;
  }

  /**
   * 複製ボタン押下
   */
  quote(): void {
    this.displayData = MonthReportInfoClass.clone(this.reportData);
    this.displayData.monthreportId = new MonthReportID();
    this.displayData.status = new MonthStatus();
    this.displayData.date = TypeJpDate.getTodayMonth();
    this.displayData.fileList = [];
    this.showDialog = true;
  }

  /**
   * 削除ボタン押下
   */
  deleteClick(): void {
    MonthReportApi.delete(this.reportData).then(() => {
      this.$emit('deleted');
    });
  }

  /**
   * 5段階表示
   */
  showMentalCircle(point:number) :string {
    let result = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; i++) {
      if (i < point) {
        result += '●';
      } else {
        result += '○';
      }
    }
    return `${result} ${point}`;
  }

  /**
   * 登録処理後
   */
  saved() :void {
    this.showDialog = false;
    this.$emit('research');
  }

  /**
   * ファイルがPDFかどうか
   */
  isPDF(fileName: string): boolean {
    if (fileName.toLowerCase().match(/\.(PDF)$/i)) {
      return true;
    }
    return false;
  }
}

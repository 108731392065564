import MonthReportID from './MonthReportID';
import TypeJpDate from '../type/TypeJpDate';
import UserID from '../user/UserID';
import Name from '../user/history/Name';
import MonthStatus from './MonthStatus';
import Thismonth from './Thismonth';
import Nextmonth from './Nextmonth';
import Environment from './Environment';
import Mental from './Mental';
import MentalScore from './MentalScore';
import Etc from './Etc';
import FileInfo from '../type/FileInfo';
import PostId from '../posts/PostId';

export default class MonthReportInfoClass {
  /** レポートID */
  monthreportId: MonthReportID;
  /** ユーザID */
  userId: UserID;
  /** 業務名 */
  name: Name;
  /** 年月度 */
  date: TypeJpDate;
  /** 投稿ID */
  postId: PostId;
  /** ステータス */
  status: MonthStatus;
  /** 今月実施 */
  thismonth: Thismonth;
  /** 来月予定 */
  nextmonth: Nextmonth;
  /** 業務環境（業務の課題） */
  envTodo: Environment;
  /** 業務環境（メンバー情報） */
  envMember: Environment;
  /** 業務環境（営業情報）;旧職員情報、他社情報、受注者情報 */
  envSales: Environment;
  /** 業務環境（その他） */
  envEtc: Environment;
  /** メンタル */
  mental: Mental;
  /** メンタル5段階(倦怠感・疲れが取れない) */
  mentalScoreT1: MentalScore;
  /** メンタル5段階(食欲不信) */
  mentalScoreT2: MentalScore;
  /** メンタル5段階(睡眠不足) */
  mentalScoreT3: MentalScore;
  /** メンタル5段階(憂鬱・不安感) */
  mentalScoreT4: MentalScore;
  /** メンタル5段階(焦燥感・イライラ) */
  mentalScoreT5: MentalScore;
  /** メンタル5段階(集中力不足・ミスが多い) */
  mentalScoreT6: MentalScore;
  /** 備考 */
  etc: Etc;
  /** 添付ファイル一覧 */
  fileList: FileInfo[];

  constructor(
    monthreportId?: MonthReportID,
    userId?: UserID,
    name?: Name,
    date?: TypeJpDate,
    postId?: PostId,
    status?: MonthStatus,
    thismonth?: Thismonth,
    nextmonth?: Nextmonth,
    envTodo?: Environment,
    envMember?: Environment,
    envSales?: Environment,
    envEtc?: Environment,
    mental?: Mental,
    mentalScoreT1?: MentalScore,
    mentalScoreT2?: MentalScore,
    mentalScoreT3?: MentalScore,
    mentalScoreT4?: MentalScore,
    mentalScoreT5?: MentalScore,
    mentalScoreT6?: MentalScore,
    etc?: Etc,
    fileList?: FileInfo[]
  ) {
    this.monthreportId = (monthreportId === undefined ? new MonthReportID() : monthreportId);
    this.userId = (userId === undefined ? new UserID() : userId);
    this.name = (name === undefined ? new Name() : name);
    this.date = (date === undefined ? new TypeJpDate() : date);
    this.postId = (postId === undefined ? new PostId() : postId);
    this.status = (status === undefined ? new MonthStatus() : status);
    this.thismonth = (thismonth === undefined ? new Thismonth() : thismonth);
    this.nextmonth = (nextmonth === undefined ? new Nextmonth() : nextmonth);
    this.envTodo = (envTodo === undefined ? new Environment() : envTodo);
    this.envMember = (envMember === undefined ? new Environment() : envMember);
    this.envSales = (envSales === undefined ? new Environment() : envSales);
    this.envEtc = (envEtc === undefined ? new Environment() : envEtc);
    this.mental = (mental === undefined ? new Mental() : mental);
    this.mentalScoreT1 = (mentalScoreT1 === undefined ? new MentalScore() : mentalScoreT1);
    this.mentalScoreT2 = (mentalScoreT2 === undefined ? new MentalScore() : mentalScoreT2);
    this.mentalScoreT3 = (mentalScoreT3 === undefined ? new MentalScore() : mentalScoreT3);
    this.mentalScoreT4 = (mentalScoreT4 === undefined ? new MentalScore() : mentalScoreT4);
    this.mentalScoreT5 = (mentalScoreT5 === undefined ? new MentalScore() : mentalScoreT5);
    this.mentalScoreT6 = (mentalScoreT6 === undefined ? new MentalScore() : mentalScoreT6);
    this.etc = (etc === undefined ? new Etc() : etc);
    this.fileList = (fileList === undefined ? [] : fileList);
  }

  public static cloneList(source:MonthReportInfoClass[]):MonthReportInfoClass[] {
    const result : MonthReportInfoClass[] = [];
    source.forEach((s) => {
      result.push(MonthReportInfoClass.clone(s));
    });
    return result;
  }

  public static clone(source: MonthReportInfoClass): MonthReportInfoClass {
    const result = new MonthReportInfoClass();
    result.monthreportId = source.monthreportId;
    result.userId = source.userId;
    result.name = source.name;
    // result.date = source.date;
    result.date = new TypeJpDate(source.date.era, source.date.year, source.date.month, source.date.day);
    result.postId = PostId.clone(source.postId);
    result.date.jpDateYearMonth = source.date.jpDateYearMonth;
    result.status = source.status;
    result.thismonth = source.thismonth;
    result.nextmonth = source.nextmonth;
    result.envTodo = source.envTodo;
    result.envMember = source.envMember;
    result.envSales = source.envSales;
    result.envEtc = source.envEtc;
    result.mental = source.mental;
    result.mentalScoreT1 = source.mentalScoreT1;
    result.mentalScoreT2 = source.mentalScoreT2;
    result.mentalScoreT3 = source.mentalScoreT3;
    result.mentalScoreT4 = source.mentalScoreT4;
    result.mentalScoreT5 = source.mentalScoreT5;
    result.mentalScoreT6 = source.mentalScoreT6;
    result.etc = source.etc;
    result.fileList = FileInfo.clone(source.fileList);
    return result;
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { ErrorResponseModel } from '@/model/StaffManager';
import MonthReportInfoClass from '@/model/monthReport/MonthReportInfoClass';
import MonthReportApi from '@/module/api/MonthReportApi';
import MonthStatus from '@/model/monthReport/MonthStatus';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import FileUpload from '@/components/Parts/FileUpload.vue';
import UserBaseInfoClass from '@/model/user/UserBaseInfo';
import FileInfo from '@/model/type/FileInfo';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import Help from '@/components/Parts/Help.vue';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UpdateFields from '@/model/user/UpdateFields';
import UserApi from '@/module/api/UserApi';
import UserID from '@/model/user/UserID';

@Component({
  components: {
    InputJpDate,
    FileUpload,
    Help
  }
})
export default class MonthlyReportRegister extends Vue {
  @Prop() reportData!: MonthReportInfoClass;
  @Prop() loginUser!: UserBaseInfoClass;
  @Prop() userId!: string;
  @Prop() newReport!: boolean;
  fileIds:UploadFileContainer[]=[];
  deleteList:FileInfo[]=[];
  addFileSeq=0;
  isRunning = false;
  userUpdateItemList:UserUpdateItemInfo[]=[];

  mounted():void{
    this.getLocalStorage();
  }

  getLocalStorage():void{
    if (this.newReport) {
      const localData = localStorage.getItem('newMonthReport');
      if (localData === null) return;
      this.reportData = MonthReportInfoClass.clone(JSON.parse(localData));
      return;
    }
    const localData = localStorage.getItem(`monthReport${this.reportData.date.jpDateYearMonth}`);
    if (localData === null) return;
    this.reportData = MonthReportInfoClass.clone(JSON.parse(localData));
  }

  deleteLocalStorage():void{
    if (this.newReport) {
      localStorage.removeItem('newMonthReport');
      return;
    }
    localStorage.removeItem(`monthReport${this.reportData.date.jpDateYearMonth}`);
  }

  changeItem():void {
    if (this.newReport) {
      localStorage.setItem('newMonthReport', JSON.stringify(this.reportData));
      return;
    }
    localStorage.setItem(`monthReport${this.reportData.date.jpDateYearMonth}`, JSON.stringify(this.reportData));
  }

  /**
   * ファイル選択
   */
  fileSelected(e: Event, idx:number): void{
    const target = e.target as HTMLInputElement;
    if (target.files && target.files?.length > 0) {
      const f = target.files.item(0);
      if (f) {
        this.fileIds[idx].file = f;
      }
    }
  }

  /**
   * ファイル削除
   */
  splice(idx:number):void {
    this.fileIds.splice(idx, 1);
  }

  /**
   * 元ファイル削除
   */
  motoSplice(idx:number):void {
    this.deleteList.push(this.reportData.fileList[idx]);
    this.reportData.fileList.splice(idx, 1);
  }

  /**
   * 登録完了ボタン押下
   */
  regist(status: string):void {
    this.isRunning = true;
    let returnData :MonthReportInfoClass = new MonthReportInfoClass();
    this.reportData.status = new MonthStatus(status);

    // 一般ユーザ又はパラメータがない場合
    if (this.userId === undefined || !this.loginUser.priv.isAdminGroup) {
      MonthReportApi.save(this.reportData)
        .then((res) => {
          returnData = MonthReportInfoClass.clone(res.data);
          this.fileUpload(returnData);
          const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(this.reportData.userId, new UpdateFields('monthReport'));
          this.userUpdateItemList.push(userUpdateItem);
          UserApi.saveUpdateItem(this.userUpdateItemList);
          this.deleteLocalStorage();
        }).catch((error: AxiosError<ErrorResponseModel>) => {
          if (error.response?.data.code === 'DuplicateKey') {
            this.$root.$emit('event-show-snackbar-infinity', '既に月末報告が登録済みの年月度です。');
            return;
          }
          this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
        }).finally(() => {
          this.isRunning = false;
        });
    // 管理者の場合
    } else if (this.loginUser.priv.isAdminGroup) {
      MonthReportApi.saveByPrivUser(this.reportData, this.userId)
        .then((res) => {
          returnData = MonthReportInfoClass.clone(res.data);
          this.fileUpload(returnData);
          const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(new UserID(this.userId), new UpdateFields('monthReport'));
          this.userUpdateItemList.push(userUpdateItem);
          UserApi.saveUpdateItem(this.userUpdateItemList);
          this.deleteLocalStorage();
        }).catch((error: AxiosError<ErrorResponseModel>) => {
          if (error.response?.data.code === 'DuplicateKey') {
            this.$root.$emit('event-show-snackbar-infinity', '既に月末報告が登録済みの年月度です。');
            return;
          }
          this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
        }).finally(() => {
          this.isRunning = false;
        });
    }
  }

  /**
   * ファイルアップロード処理
   */
  fileUpload(returnData: MonthReportInfoClass): void {
    // ファイルアップロード
    const data = new FormData();
    data.append('monthreportId', returnData.monthreportId.value);

    // 登録ファイル
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.fileIds.length; i++) {
      const f = this.fileIds[i].file;
      if (f) {
        data.append('postfiles', f);
      }
    }

    // 削除ファイル
    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < this.deleteList.length; k++) {
      const h = this.deleteList[k].id.value;
      if (h) {
        data.append('deleteFiles', h);
      }
    }
    MonthReportApi.upload(data).then(() => {
      this.$emit('saved');
    }).catch(() => {
      this.$root.$emit('event-show-snackbar-infinity', 'ファイルアップロードに失敗しました。後程ファイルをアップロードし直してください。');
      this.$emit('saved');
    });
  }
  /**
   * 入力不備あり判定
   */
  isInvalid(): boolean {
    // 業務名
    if (this.reportData.name.value.length === 0) return true;

    // 今月実施
    if (this.reportData.thismonth.value.length === 0) return true;

    // 来月予定
    if (this.reportData.nextmonth.value.length === 0) return true;

    // レポート日付入力不備
    if (!this.reportData.date.isValid) return true;
    return false;
  }

  /**
   * 登録ボタン使用不可判定
   */
  get disableSave(): boolean {
    // 処理中は使用不可
    if (this.isRunning) return true;
    // 入力不備あれば使用不可
    if (this.isInvalid()) return true;
    return false;
  }
  /**
   * 下書き保存ボタン使用不可判定
   */
  get disableDraft(): boolean {
    if (this.disableSave) return true;
    // 公開済みなら下書き不可
    if (this.reportData.status.value === '1') return true;
    return false;
  }

  /**
   * ファイルがPDFかどうか
   */
  // isPDF(fileName: string): boolean {
  //   if (fileName.toLowerCase().match(/\.(PDF)$/i)) {
  //     return true;
  //   }
  //   return false;
  // }
}

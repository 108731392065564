import UpdateFields from './UpdateFields';
import UserID from './UserID';

export default class UserUpdateItemInfo {
  userId:UserID;

  updateFields:UpdateFields;

  constructor(
    userId?:UserID,
    updateFields?:UpdateFields
  ) {
    this.userId = (userId === undefined ? new UserID() : userId);
    this.updateFields = (updateFields === undefined ? new UpdateFields() : updateFields);
  }
}
